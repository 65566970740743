import { Grid } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { dashboardStore, patientsStore } from 'stores';

import { PanelWrapper } from 'components';

import OthersGraphics from './components/BoxPlotChart/OthersGraphics';
import JointLineChart from './components/JointLineChart/JointLineChart';
import { ActiveMusclesPieChart, PatientInfo, StatisticsTable } from './components';

import './StatisticsComponent.scss';

const cnStatisticsComponent = block('StatisticsComponent');

export const isLaptop = window.screen.width < 1279;
export const isMobile = window.screen.width < 767;

const StatisticsComponent = (props: { tab?: string | undefined; columnsNames?: any }) => {
 
  return (
    <div className={cnStatisticsComponent()}>
      <Grid container spacing={2}  className={cnStatisticsComponent('container').toString()}>
        <Grid
          item
          xs={isMobile ? 12 : isLaptop ? 4 : 2}
          height={dashboardStore.selectedJoint || dashboardStore.selectedGesture ? isMobile ? '45%' : '40%' : '100%'}
          className={cnStatisticsComponent('item').toString()}
        >
          <PatientInfo patient={patientsStore.patient} tab={props?.tab} />
        </Grid>

        <Grid
          item
          xs={isMobile ? 12 : isLaptop ? 8 : 10}
          height={dashboardStore.selectedJoint || (dashboardStore.selectedGesture && props.tab == 'gesture') ? isMobile ? '45%' : '45vh' : '100%'}
          className={cnStatisticsComponent('item').toString()}
        >
          <StatisticsTable tab={props?.tab} columnsNames={props.columnsNames} />
        </Grid>

        {dashboardStore.selectedJoint && (
          <>
            {(!dashboardStore.selectedJoint.jointData2 && props.tab !== 'power') && (
              <Grid item xs={3} height={'60%'} className={cnStatisticsComponent('item').toString()}>
                <PanelWrapper>
                  <ActiveMusclesPieChart />
                </PanelWrapper>
              </Grid>
            )}

            <Grid
              item
              xs={!dashboardStore.selectedJoint.jointData2 ? props.tab !== 'power' ? 9 : false : 12}
              height={'60%'}
              className={cnStatisticsComponent('item').toString()}
            >
              <PanelWrapper className={dashboardStore.selectedJoint.jointData2 ? 'test' : 'base'}>
                <JointLineChart />
              </PanelWrapper>
            </Grid>
          </>
        )}
        {(dashboardStore.selectedGesture && props.tab == 'gesture') && (
            <Grid item xs={12} height={isLaptop ? '100%':'60%'} className={cnStatisticsComponent('item').toString()}>
              <PanelWrapper className='graphics'>
                {/* <BoxPlotChart /> */}
                <OthersGraphics />
              </PanelWrapper>
            </Grid>
        )}
      </Grid>
    </div>
  );
};

export default observer(StatisticsComponent);

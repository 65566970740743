import { observer } from 'mobx-react';

import poster from '../../../../assets/load.jpg';
import Video from '../../../../assets/timeline-1.mp4';
import Video2 from '../../../../assets/timeline-2.mp4';
import Video3 from '../../../../assets/timeline-3.mp4';
import Video4 from '../../../../assets/timeline-4.mp4';
import Video5 from '../../../../assets/timeline-5.mp4';

import './HelpTooltip.scss';

interface HelpTooltipProps {
  setShowHelp: any;
  steps: number[];
  currentStep: number;
  setCurrentStep: any; // Добавляем currentStep в пропсы
}

const HelpTooltip = ({ setShowHelp, steps, setCurrentStep, currentStep }: HelpTooltipProps) => {
  const texts = [
    { step: 1, text: 'Можно выбирать дату и уровень у выбранного пациента.', videoSrc: Video },
    {
      step: 2,
      text: 'Посмотреть жесты пациента или больше статистики и графиков.',
      videoSrc: Video2,
    },
    {
      step: 3,
      text: 'Панель управления моделью, кнопки вращения, автоматическое вращение модели по оси X, а также выбор другого пациента.',
      videoSrc: Video3,
    },
    {
      step: 4,
      text: 'Реалистичная модель мышц человека и кнопки для управления вращения при необходимости, модель можно вращать нажимая и удерживая мышь в свободное пространство.',
      videoSrc: Video4,
    },
    {
      step: 5,
      text: 'Выберите интересующую вас мышцу и кликнете на неё. После чего появиться 2 модальных окна где вы сможете видеть информацию о выбранной мышце. На правой и левой руке соответсвенно. Более того, после того как вы выберете мышцу, на модели она будет выделяться на обоих руках.',
      videoSrc: Video5,
    },
  ];

  // Проверка на существование шага
  if (!steps || !steps.includes(currentStep)) {
    return null;
  }

  const currentStepData = texts.find((step) => step.step === currentStep);

  if (!currentStepData) return null; // Добавленная проверка на случай, если шаг не найден

  return stepContent(
    currentStepData.text,
    setShowHelp,
    currentStep,
    setCurrentStep,
    steps,
    currentStepData.videoSrc,
  );
};

const stepContent = (
  title: string,
  setShowHelp: any,
  currentStep: number,
  setCurrentStep: (step: number) => void, // Добавляем функцию для обновления состояния
  steps: number[],
  videoSrc: string,
) => {
  return (
    <div className={`helpTooltip step${currentStep}`}>
      <div className="helpTooltip__title">{title}</div>

      <video
        className="helpTooltip__video"
        muted
        controls={false}
        loop={true}
        autoPlay
        src={videoSrc}
        poster={poster}
        preload='auto'
      />

      <button
        className="helpTooltip__btn"
        onClick={() => {
          if (currentStep === steps.length) {
            setShowHelp(false);
          } else {
            setCurrentStep(currentStep + 1); // Обновляем состояние через setCurrentStep
          }
        }}
      >
        Понятно!
      </button>
    </div>
  );
};

export default observer(HelpTooltip);

export class MathUtils {
  /**
   * Функция для вычисления среднего значения
   */
  public static calculateMean(data: number[]): number {
    const sum = data.reduce((acc, value) => acc + value, 0);
    return sum / data.length;
  }

  /**
   * Функция для вычисления стандартного отклонения
   */
  public static calculateStandardDeviation(data: number[]): number {
    const mean = MathUtils.calculateMean(data);
    const squaredDifferences = data.map((value) => Math.pow(value - mean, 2));
    const sumSquaredDifferences = squaredDifferences.reduce((acc, value) => acc + value, 0);
    const variance = sumSquaredDifferences / data.length;
    return Math.sqrt(variance);
  }

  public static calculateOutOfControl(data: number[], ucl: number, lcl: number): number {
    let oocCount = 0;
    let res: number = 0;

    for (let i = 0; i < data.length; i++) {
      if (data[i] >= ucl || data[i] <= lcl) oocCount++;

      res += (oocCount / (i + 1));
    }

    return res;
  }

  /**
   * Функция для вычисления минимального значения
   */
  public static calculateMin(data: number[]): number {
    return Math.min(...data);
  }

  /**
   * Функция для вычисления максимального значения
   */
  public static calculateMax(data: number[]): number {
    return Math.max(...data);
  }

  public static quantileSorted(
    values: number[],
    p: number,
    valueof: (value: number, index: number, array: number[]) => number = (value) => value,
  ) {
    if (!(n = values.length) || isNaN((p = +p))) return;
    if (p <= 0 || n < 2) return +valueof(values[0], 0, values);
    if (p >= 1) return +valueof(values[n - 1], n - 1, values);
    /* eslint-disable */
    var n: number,
      i: number = (n - 1) * p,
      i0: number = Math.floor(i),
      value0: number = +valueof(values[i0], i0, values),
      value1: number = +valueof(values[i0 + 1], i0 + 1, values);

    return value0 + (value1 - value0) * (i - i0);
  }

  public static getSummaryStats = (data: any[]) => {
    const sortedData = data.slice().sort(function (a, b) {
      return a - b;
    });
    const test = JSON.parse(JSON.stringify(sortedData[0].filter(Boolean)));
    const q1 = MathUtils.quantileSorted(test, 0.25);
    const median = MathUtils.quantileSorted(test, 0.5);
    const q3 = MathUtils.quantileSorted(test, 0.75);

    if (!q3 || !q1 || !median) {
      return;
    }

    const interQuantileRange = q3 - q1;
    const min = q1 - 1.5 * interQuantileRange;
    const max = q3 + 1.5 * interQuantileRange;

    return { min, q1, median, q3, max };
  };
}

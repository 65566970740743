

export interface IHandModalItem {
  title: string;
  value: string;
}

const HandModalItem = ({ title, value }: IHandModalItem) => {
  return (
    <div className={`hm-content`}>
      <div className="hm-tab-content title">
        {title}
      </div>
      <div className="hm-tab-content energy">{value}</div>
    </div>
  );
};

export default HandModalItem;

import * as React from 'react';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import {  patientsStore } from 'stores';

import Levels from 'pages/MainPage/components/Levels/Levels';
import PatientsList from 'pages/MainPage/components/PatientsList/PatientsList';

import Gestures from './Gestures/Gestures';

import './Modal.scss';

const cnModal = block('Modal');
function Modal({ title, anchor, type, icon, className, event }: any) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    event()
  };

  useEffect(() => {
    if (open && patientsStore.patients.length == 0 && type !== 'Gestures') {
      patientsStore.getPatients();
    }
  }, [open]);


  const DrawerList = (
    <Box sx={{ width: '60rem' }} role="presentation">
      <CloseIcon className="closeicon" onClick={() => setOpen(false)} />
      <List sx={{ paddingTop: 0 }}>
        
          <div className={cnModal()}>
            <Box className={cnModal('pageContent').toString()}>
              <Stack className={cnModal('patientsList').toString()}>
                <PatientsList dynamic={true} />
              </Stack>
              <Stack className={cnModal('Levels').toString()}>
                <Levels dynamic={true} />
              </Stack>
            </Box>
          </div>
      </List>
    </Box>
  );

  

  return (
    <>
      <div className={className}  onClick={toggleDrawer(true)}>
        {icon || <GroupIcon />} {title ?? 'Настройки'}
      </div>
      <Drawer open={open} anchor={anchor || 'left'} onClose={toggleDrawer(false)}>
        {type === 'Gestures' ? <Gestures open={open} setOpen={setOpen} /> : DrawerList}
      </Drawer>
    </>
  );
}

export default observer(Modal);

export const muscleVertexShader = () => `
    attribute vec3 position;
    attribute vec3 normal;

    uniform vec4 color;
    uniform float needHighlight;

    uniform mat4 modelViewMatrix;
    uniform mat4 projectionMatrix;
    uniform mat3 normalMatrix; // Матрица нормалей для преобразования нормалей в мировое пространство

    varying vec4 vColor;
    varying vec3 vNormal;
    varying vec3 vPosition;

    void main() {
        vColor = float(needHighlight) == 1.0 ? vec4(0.0, 1.0, 0.0, 1.0) : color;
        vNormal = normalize(normalMatrix * normal); // Преобразуем нормали
        vPosition = (modelViewMatrix * vec4(position, 1.0)).xyz; // Позиция в мировом пространстве
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;

export const muscleFragmentShader = () => `
    precision highp float;

    uniform vec3 ambientColor;
    uniform vec3 lightPosition; // Позиция источника света в мировом пространстве
    uniform vec3 lightColor; // Цвет света
    uniform float shininess; // Коэффициент блеска

    varying vec4 vColor;
    varying vec3 vNormal;
    varying vec3 vPosition;

    void main() {
        // Нормализуем нормаль и вектор к источнику света
        vec3 normal = normalize(vNormal);
        vec3 lightDir = normalize(lightPosition - vPosition);

        // Расчет диффузного освещения
        float diff = max(dot(normal, lightDir), 0.0);

        // Расчет бликов (specular)
        vec3 viewDir = normalize(-vPosition); // Направление на камеру
        vec3 reflectDir = reflect(-lightDir, normal);
        float spec = pow(max(dot(viewDir, reflectDir), 0.0), shininess);

        // Итоговый цвет
        vec3 ambient = ambientColor * vColor.rgb;
        vec3 diffuse = diff * lightColor * vColor.rgb;
        vec3 specular = spec * lightColor;

        vec3 finalColor = ambient + diffuse + specular;

        gl_FragColor = vec4(finalColor, vColor.a);
    }
`;


import BaseApiService from './BaseApiService';
  
  class SceneApiService extends BaseApiService {
    constructor() {
      super('api');
    } 
    postTask(user: string,level:string): Promise<any> {
      return this.POST(`data_models`, { user: user, level_name: level });
    }
  }
  
  export default new SceneApiService();
  
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { RouterPathEnum } from 'shared';

import { ReactComponent as SsmuLogo } from '../../../../Icons/ssmuLogo.svg'

interface IDashboard {
    showHelp: boolean,
    currentStep: number,
    user: string,
    currentData: string,
    currentLevel: number,
    setCurrentLevel: React.Dispatch<React.SetStateAction<number>>,
    setCurrentData: React.Dispatch<React.SetStateAction<string>>,
}

const Dashboard = React.memo(({ showHelp, currentStep, user, currentData, currentLevel,setCurrentLevel,setCurrentData }: IDashboard) => {
  
const navigate = useNavigate();
const Levels = [1, 2, 3, 4, 5, 6];

const handleLevelChange = (newLevel: number) => {
    setCurrentLevel(newLevel);
  };

  const handleDateChange = (date: string) => {
    setCurrentData(date);
  };

  return (
    <div className="dashboard">
        <div
          className={`logoWrapper ${showHelp && currentStep != 1 ? 'showBlur' : ''}`}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => navigate(RouterPathEnum.Main)}
        >
          <SsmuLogo className="logo" /> <span className="logo-text">Нейро-Kinesis</span>
        </div>
        <div className={`keyMscle ${showHelp && currentStep != 1 ? 'showBlur' : ''}`}>
          Пациент: <p className="patientInfo">{user}</p>
          <br />
          Дата:{' '}
          <p className="patientInfo">
            {currentData === 'first' ? 'Первая' : currentData === 'midle' ? 'Средняя' : 'Последняя'}
          </p>
          <br />
          Уровень: <p className="patientInfo">{currentLevel}</p>
        </div>
        <div className={`dashboard__header ${showHelp && currentStep != 1 ? 'showBlur' : ''}`}>
          <span className="dashboard_date">Даты:</span>
          <div className="dashboard__headerBtns">
            <Button
              key={'first'}
              variant="outlined"
              className="dashboard__header-button"
              onClick={() => handleDateChange('first')}
            >
              Первая
            </Button>
            <Button
              key={'midle'}
              className="dashboard__header-button"
              variant="outlined"
              onClick={() => handleDateChange('midle')}
            >
              Средняя
            </Button>
            <Button
              key={'last'}
              className="dashboard__header-button"
              variant="outlined"
              onClick={() => handleDateChange('last')}
            >
              Последняя
            </Button>
          </div>
        </div>

        <div className={`lvls-btn-wrapper ${showHelp && currentStep != 1 ? 'showBlur' : ''}`}>
          <span>Уровни:</span>
          {Levels.map((level: number) => {
            return (
              <Button
                key={level}
                variant="outlined"
                className="lvls-btn"
                onClick={() => handleLevelChange(level)}
              >
                {level}
              </Button>
            );
          })}
        </div>
      </div>
  );
});

export default Dashboard;
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
//import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { dashboardStore, patientsStore } from 'stores';

import { StatisticsTable } from 'pages/DashboardPage/components/StatisticsComponent/components';
import BoxPlotChart from 'pages/DashboardPage/components/StatisticsComponent/components/BoxPlotChart';
import {
  isLaptop,
  isMobile,
} from 'pages/DashboardPage/components/StatisticsComponent/StatisticsComponent';
import { DashboardPageHand, testData } from 'pages/DashboardPage/DashboardPage';
import Loader from 'components/CommonComponents/Loader';

import './Gestures.scss';

const cnGestures = block('Gestures');
function Gestures({ setOpen }: any) {
  const systemData = {
   
    gesture_: [
      dashboardStore.GestureLoader,
      dashboardStore.GestureLoader2,
      dashboardStore.GestureLoader3,
    ],
  };

  const changeHand = (hand: 'left' | 'right') => {
    patientsStore.isLoading = true;
   
    systemData['gesture_'].map((dataLoader, i: number) => {
      dataLoader.parseGestureData(
        patientsStore.patientDateCSV['gesture_' + hand + (i === 0 ? '' : `_${i}`)],
      );
    });

    dashboardStore.setJointUpdate(hand);
    dashboardStore.setStats(patientsStore.patientDateCSV[`stats_${dashboardStore.jointHand}`]);
    if (!!patientsStore.userDataGesture) {
      const allValues = [
        patientsStore.userDataGesture[`data_${hand}_g_num_rows`],
        patientsStore.userDataGesture[`data_${hand}_g1_num_rows`],
        patientsStore.userDataGesture[`data_${hand}_g2_num_rows`],
        patientsStore.userDataGesture[`data_${hand}_g1_medians`],
        patientsStore.userDataGesture[`data_${hand}_g2_medians`],
        patientsStore.userDataGesture[`data_${hand}_g_medians`],
        patientsStore.userDataGesture[`data_${hand}_g_repeat`],
        patientsStore.userDataGesture[`data_${hand}_g1_repeat`],
        patientsStore.userDataGesture[`data_${hand}_g2_repeat`],
      ];
      const filtredAllValues = allValues
        .map((item) => {
          return parseInt(item);
        })
        .sort(function (a, b) {
          return b - a;
        });
      dashboardStore.setSizeRadarChartValue(filtredAllValues[0]);
      dashboardStore.setSelectedGesture('bla', testData(hand));
    }
    patientsStore.isLoading = false;
    
  };

  const onClickLeft = () => {
    changeHand(DashboardPageHand.Left);
  };

  const onClickRight = () => {
    changeHand(DashboardPageHand.Right);
  };

  useEffect(() => {
      if (patientsStore.patientDateCSV) {
        patientsStore.isLoading = true;
        changeHand(
          patientsStore.patient?.defeat == '1' ? DashboardPageHand.Left : DashboardPageHand.Right,
        );
        patientsStore.isLoading = false;
      }
  }, [patientsStore.patientDateCSV]);

  return (
    <Box sx={{ width: '60rem' }} role="presentation">
      <CloseIcon className="closeicon" onClick={() => setOpen(false)} />
      <List sx={{ paddingTop: 0 }}>
        {patientsStore.isLoading && <Loader visible />}
        <div className={cnGestures()}>
          <div className={cnGestures('buttons').toString()}>
            Выбор руки:
            <div className={cnGestures('buttonWrap').toString()}>
              <Button
                variant="contained"
                className={cnGestures('button').toString()}
                onClick={onClickLeft}
                disabled={dashboardStore.jointHand == 'left' ? true : false}
              >
                Левая
              </Button>
              <span style={{ display: patientsStore.patient?.defeat == '1' ? undefined : 'none' }}>
                Поврежденная
              </span>
            </div>
            <div className={cnGestures('buttonWrap').toString()}>
              <Button
                variant="contained"
                className={cnGestures('button').toString()}
                onClick={onClickRight}
                disabled={dashboardStore.jointHand == 'right' ? true : false}
              >
                Правая
              </Button>
              <span style={{ display: patientsStore.patient?.defeat == '1' ? 'none' : undefined }}>
                Поврежденная
              </span>
            </div>
          </div>
          <Grid
            item
            xs={isMobile ? 12 : isLaptop ? 8 : 10}
            height={
              dashboardStore.selectedJoint || dashboardStore.selectedGesture
                ? isMobile
                  ? '45%'
                  : '45vh'
                : '100%'
            }
            className={'item'}
          >
            <Grid
              item
              xs={isMobile ? 12 : isLaptop ? 8 : 10}
              height={
                dashboardStore.selectedJoint || dashboardStore.selectedGesture
                  ? isMobile
                    ? '45%'
                    : '45vh'
                  : '100%'
              }
              className={"item"}
            >
              <StatisticsTable tab={"gesture"} columnsNames={!!Object.keys(dashboardStore.GestureLoader2.data).length
                ? ['Жест', `Медиана За первую дату`, 'За среднюю дату', 'За последнюю дату']
                : ['Жест', 'Медиана']} />
            </Grid>
          </Grid>
          {!!patientsStore.patientDateCSV && <BoxPlotChart />}
        </div>
      </List>
    </Box>
  );
}

export default observer(Gestures);

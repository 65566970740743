import { useEffect } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { patientsStore, sceneStore } from 'stores';

import { Loader, PanelWrapper } from 'components';
import { RouterPathEnum } from 'shared';

import Footer from './components/Footer';
import Levels from './components/Levels';
import { MainPageHeader, PatientsList } from './components';

import './MainPage.scss';

const cnMainPage = block('MainPage');

export enum Status {
  Doctor = '1',
  Patient = '2',
  Manager = '3',
}

const MainPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    patientsStore.getProfile();
    patientsStore.getPatients();


    const statusCheck = setTimeout(() => {
      if (patientsStore.client?.status == Status.Manager) {
        navigate(RouterPathEnum.DoctorPage);
      }
    }, 500);

    const notAuth = setTimeout(() => {
      if (patientsStore.client?.status == undefined) {
        navigate(RouterPathEnum.Auth);
      }
    }, 3000);

    return () => {
      patientsStore.deInit();
      clearTimeout(statusCheck);
      clearTimeout(notAuth);
    };
  }, []);

  return (
    <div className={cnMainPage()}>
      <Box className={cnMainPage('header').toString()}>
        <MainPageHeader />
      </Box>
      {patientsStore.isLoading ? (
        <Loader visible={patientsStore.isLoading} />
      ) : patientsStore.client?.status == Status.Doctor ? (
        <Box className={cnMainPage('pageContent').toString()}>
          <Stack className={cnMainPage('patientsList').toString()}>
            <PatientsList />
          </Stack>
          <Stack className={cnMainPage('Levels').toString()}>
            <Levels dynamic={true} />
          </Stack>
        </Box>
      ) : (
        patientsStore.client?.status == Status.Patient && (
          <PanelWrapper>
            <div>Привет Я пациент!)</div>
          </PanelWrapper>
        )
      )}
      <Footer />
    </div>
  );
};

export default observer(MainPage);

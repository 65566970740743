import { makeAutoObservable, observable } from 'mobx';
import {
  // AmbientLight,
  Box3,
  CanvasTexture,
  Color,
  MathUtils,
  PerspectiveCamera,
  PointLight,
  Scene,
  Sprite,
  SpriteMaterial,
  Vector2,
  Vector3,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';

import { SceneUtils } from 'shared';
import { ISceneStore } from 'shared/interfaces';
import { HandProcessingModel } from 'shared/models';

const isDebug = true;

const stats = new Stats();

isDebug && document.body.appendChild(stats.dom);

class SceneStore implements ISceneStore {
  public mainCanvas: HTMLCanvasElement;
  public context: WebGLRenderingContext;

  public controls: OrbitControls;

  public scene = new Scene();
  public muscle: any[] = [];
  public camera: PerspectiveCamera = new PerspectiveCamera();

  public renderer: WebGLRenderer | null = null;
  public  effectComposer: EffectComposer;
  public outlinePass: OutlinePass | null = null;

  public Musculs: any = [];

  public gradientMuscle: any = null;

  public angles: any = null;

  public handModel: HandProcessingModel | null = null;

  private lights: PointLight[] = [];

  public user: string = '';

  public modelIndex: number = 2;

  public isRotating: boolean = false;

  public targetRotationY: number = 0;

  public targetRotationZ: number = 0;

  public showButtons: boolean = true;

  public autoRotate: boolean = false;

  public intersection: any = [];

  public modalInfo: any = null;

  public hoverInfo: any = null;

  public strengh: any = null;

  public power: any = null;

  public joints: any = null;

  public currentLvl: any = 1;

  public isLoading: boolean = false;

  private readonly mouseDownEvent!: (event: MouseEvent) => void;

  private readonly mouseWheelEvent!: (event: MouseEvent) => void;

  private readonly mouseUpEvent!: (event: MouseEvent) => void;

  @observable rendererInfo = { ...(this.renderer?.info || {}) }; // Наблюдаемое свойство

  constructor() {
    //надо пофиксить оптимизацию перехода и ухода со страницы....!!!!!!!!!!!!!!!!!!
    makeAutoObservable(this, { rendererInfo: observable, }, { autoBind: true });

    const canvas = document.createElement('canvas');
    const spriteCanvas = document.createElement('canvas');
    const spriteCanvasR = document.createElement('canvas');

    

    canvas.style.position = 'absolute';
    canvas.style.left = '0';
    canvas.style.top = '0';
    canvas.style.zIndex = '0';

    this.mainCanvas = canvas;
    this.context = this.mainCanvas.getContext('webgl2') || new WebGLRenderingContext();

    this.controls = new OrbitControls(this.camera, canvas);
    this.renderer = new WebGLRenderer({
      canvas: this.mainCanvas,
      context: this.context,
      precision: 'highp',
      powerPreference: 'high-performance',
      antialias: true,
      alpha: true
    });
    this.renderer.shadowMap.enabled = false;
    this.effectComposer = new EffectComposer(this.renderer);

    const spriteCtx = spriteCanvas.getContext('2d')!;
    const spriteCtxR = spriteCanvasR.getContext('2d')!;
    spriteCtx.font = '48px sans-serif'; // Увеличенный размер шрифта
    spriteCtx.fillStyle = 'white';
    spriteCtx.textAlign = 'center';
    const text = 'Левая';
    spriteCtx.fillText(text, spriteCanvas.width / 2, spriteCanvas.height / 2 + 6); // Вертикальное центрирование

    const spriteTexture = new CanvasTexture(spriteCanvas);
    const spriteMaterial = new SpriteMaterial({ map: spriteTexture });
    const sprite = new Sprite(spriteMaterial);
    sprite.scale.set(2, 2, 1); // Увеличенный масштаб
    sprite.position.set(1.25, -1.5, 0); // Измененная позиция
    spriteCtxR.font = '48px sans-serif'; // Увеличенный размер шрифта
    spriteCtxR.fillStyle = 'white';
    spriteCtxR.textAlign = 'center';
    const textR = 'Правая';
    spriteCtxR.fillText(textR, spriteCanvasR.width / 2, spriteCanvasR.height / 2 + 6); // Вертикальное центрирование

    const spriteTextureR = new CanvasTexture(spriteCanvasR);
    const spriteMaterialR = new SpriteMaterial({ map: spriteTextureR });
    const spriteR = new Sprite(spriteMaterialR);
    spriteR.scale.set(2, 2, 1); // Увеличенный масштаб
    spriteR.position.set(-1.25, -1.5, 0); // Измененная позиция
    this.scene.add(sprite);
    this.scene.add(spriteR);

    if (!spriteCtx) {
      console.error('Не удалось получить контекст 2D!');
      return; // Или обработайте ошибку иначе
    }

    spriteCtx.fillStyle = 'white'; // Цвет текста
    spriteCtx.fillText(text, spriteCanvas.width / 2, spriteCanvas.height / 2 + 6);
    let wheelEventEndTimeout: any = null;

    this.mouseDownEvent = () => {
      this.setShowButtons(false);
      this.handModel?.handleClick(this.intersection);
    };

    this.mouseUpEvent = () => {
      this.setShowButtons(true);

      if(this.intersection.length <=0){
        this.modalInfo = null;
      }
    };



    this.mouseWheelEvent = () => {
      this.setShowButtons(false);
      clearTimeout(wheelEventEndTimeout);
      wheelEventEndTimeout = setTimeout(() => {
        this.setShowButtons(true);
      }, 200);
    };

    this.renderer.domElement.addEventListener('mousedown', this.mouseDownEvent);
    this.renderer.domElement.addEventListener('mouseup', this.mouseUpEvent);
    this.renderer.domElement.addEventListener('wheel', this.mouseWheelEvent);

    this.controls.autoRotate = this.autoRotate;
    this.controls.autoRotateSpeed = 0.5;
  } 

  public setShowButtons(showbtn: boolean) {
    this.showButtons = showbtn;
  }

  public setAutoRotate(autoRotate: boolean) {
    this.autoRotate = autoRotate;
    this.controls.autoRotate = autoRotate;
  }

  public setMuscle(muscle: string) {
    this.Musculs.push(muscle);
  }

  public setModelIndex(index: number) {
    this.modelIndex = index;
  }

  public setUser(user: string) {
    this.user = user;
    localStorage.setItem('user', user);
  }
  public setMuscleClear() {
    this.Musculs = [];
  }

  public setGradientMuscle(muscles: Record<string, any[]> | []) {
    const firstObject: Record<string, any> = {};
    const secondObject: Record<string, any> = {};
    const thirdObject: Record<string, any> = {};

    for (const [key, value] of Object.entries(muscles)) {
        firstObject[key] = value[0];  // Первый элемент
        secondObject[key] = value[1]; // Второй элемент
        thirdObject[key] = value[2];  // Третий элемент
    }

    this.strengh = secondObject;
    this.power = thirdObject;
    this.gradientMuscle = firstObject;
}

public setJointsData(joints: Record<string, any[]> | []) {
  this.joints = joints;
}

  public setIntersection(intersection: any) {
    this.intersection = intersection;
  }

  public setActiveMuscleNull() {
    this.muscle = [];
  }

  public zoomTo(bb: Box3, zoomFactor = 1) {
    const boxSize = bb.getSize(new Vector3());

    const maxDimension = Math.max(boxSize.x, boxSize.y, boxSize.z);

    this.camera.position.set(
      maxDimension * zoomFactor * -0.3,
      maxDimension * zoomFactor * 0.5,
      maxDimension * zoomFactor * -2.2,
    );

    this.scene.children[this.modelIndex]?.position.set(0, 0.6, 0);
    this.scene.children[this.modelIndex]?.rotateY(3.25)
  }

  public setCameraPosition(direction: string) {
    this.isRotating = true;

    if (direction === 'left') {
      this.targetRotationY = this.scene.rotation.y - Math.PI / 2;
    } else if (direction === 'right') {
      this.targetRotationY = this.scene.rotation.y + Math.PI / 2;
    } else if (direction === 'top') {
      this.targetRotationZ = this.scene.rotation.x - Math.PI / 2;
    } else {
      this.targetRotationZ = this.scene.rotation.x + Math.PI / 2;
    }

    const startTime = performance.now(); // Запоминаем время начала анимации
    const duration = 800; // Длительность анимации в миллисекундах

    const animate = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Прогресс анимации (0-1)
      const easing = Math.sin((progress * Math.PI) / 2); // Сглаживание анимации (синусоида)

      if (direction === 'left' || direction === 'right') {
        this.scene.rotation.y = MathUtils.lerp(this.scene.rotation.y, this.targetRotationY, easing);
      } else {
        // direction === 'top' || direction === 'bottom'
        this.scene.rotation.x = MathUtils.lerp(this.scene.rotation.x, this.targetRotationZ, easing);
      }

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        this.isRotating = false;
        // Устанавливаем точное значение в конце, чтобы избежать дрейфа
        if (direction === 'left' || direction === 'right') {
          this.scene.rotation.y = this.targetRotationY;
        } else {
          this.scene.rotation.x = this.targetRotationZ;
        }
      }
    };

    requestAnimationFrame(animate);
  }

  public initModel() {
    this.handModel = new HandProcessingModel(this);
    this.handModel.init(this.lights);
  }

  resetRendererInfo() {
    this.rendererInfo = { ...(this.renderer?.info || {}) }; // Сбрасываем до текущего состояния renderer.info
  }

  public render() {
    requestAnimationFrame(this.render.bind(this));

    isDebug && stats.begin();

    this.controls.update();

    this.effectComposer?.render();

    isDebug && stats.end();
    this.resetRendererInfo(); // Сбрасываем rendererInfo после рендеринга
  }

  public updateSceneSizes(width: number, height: number) {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    // Update renderer
    this.renderer?.setSize(width, height);
    this.effectComposer.setSize(width, height);
    this.renderer?.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  public initScene(wrapper: HTMLDivElement) {
    wrapper.prepend(this.mainCanvas);
    

  this.updateSceneSizes(wrapper.clientWidth, wrapper.clientHeight);

    this.camera.position.z = 1;
    this.controls.update();

    const renderPass = new RenderPass(this.scene, this.camera);

    this.effectComposer.addPass(renderPass);

    this.outlinePass = new OutlinePass(
      new Vector2(wrapper.clientWidth, wrapper.clientHeight),
      this.scene,
      this.camera,
    );
    this.outlinePass.visibleEdgeColor = new Color(0.7, 0.7, 0.7);
    this.outlinePass.hiddenEdgeColor = new Color(0.15, 0.15, 0.15);
    this.outlinePass.edgeStrength = 10;
    this.effectComposer.addPass(this.outlinePass);

    (window as any).scene = this.scene;

    function removeDuplicateGroups(arr: any) {
      // Создаем массив для хранения индексов элементов с type = 'Group'
      const groupIndices = [];

      // Проходим по массиву и находим индексы элементов с type = 'Group'
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].clearDepth === false) {
          groupIndices.push(i);
        }
      }

      // Удаляем все элементы с type = 'Group', кроме последнего
      for (let i = groupIndices.length - 2; i >= 0; i--) {
        const indexToRemove = groupIndices[i];
        arr.splice(indexToRemove, 1);

        // Корректируем индексы после удаления элемента
        for (let j = i; j < groupIndices.length - 1; j++) {
          groupIndices[j] = groupIndices[j] - 1;
        }
      }

      return arr;
    }

    const Passes = removeDuplicateGroups(this.effectComposer.passes);
    this.effectComposer.passes = Passes;
    this.render();
  }

  public checkIntersection(mousePosition: Vector2) {
    if (!this.outlinePass) return;

    SceneUtils.raycaster.setFromCamera(mousePosition, this.camera);
    const intersects = SceneUtils.raycaster.intersectObject(this.scene);
    this.handModel?.updateModelByIntersections(intersects);
    this.setIntersection(intersects);
  }

  public clearHandsModel() {
    this.handModel?.clear();
  }

  public deInit() {
    this.clearHandsModel();

    if (this.outlinePass) {
      this.effectComposer.removePass(this.outlinePass);
      this.outlinePass.dispose();
      this.outlinePass = null;
    }

    // Clean up other resources.  Order matters here too.
    //this.scene.clear(); // Очистите сцену от всех объектов
    this.lights = [];
    this.handModel = null;
  }

  public fullDeInit() {
    this.deInit();

    const renderPassIndex = this.effectComposer.passes.findIndex(
      (pass) => pass instanceof RenderPass,
    );
    if (renderPassIndex !== -1) {
      const renderPass = this.effectComposer.passes[renderPassIndex];
      this.effectComposer.removePass(renderPass);
      // Если RenderPass имеет метод dispose, вызовите его
      // renderPass.dispose();
    }

    // Удаление слушателей событий
    if (this.renderer && this.renderer.domElement) {
      this.renderer.domElement.removeEventListener('mousedown', this.mouseDownEvent);
      this.renderer.domElement.removeEventListener('wheel', this.mouseWheelEvent);
      this.renderer.domElement.removeEventListener('mouseup', this.mouseUpEvent);
    }
    this.effectComposer.dispose();
    //this.effectComposer = null;

    this.renderer?.dispose();
    this.renderer = null; // Important: set the reference to null
    // Дополнительно очистите все ссылки на объекты
    // this.mouseDownEvent = null;
    // this.mouseWheelEvent = null;
    // this.mouseUpEvent = null;
  }
}

export default new SceneStore();

import { useEffect, useRef, useState } from 'react';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  RectangleProps,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';

import { dashboardStore } from 'stores';

import './BoxPlotChart.scss';

type BoxPlot = {
  name: any;
  min: number;
  lowerQuartile: number;
  median: number;
  upperQuartile: number;
  max: number;
  average?: number;
};

// Used in stacked bar graph
type BoxPlotData = {
  name: any;
  min: number;
  bottomWhisker: number;
  bottomBox: number;
  topBox: number;
  topWhisker: number;
  average?: number;
  size: number; // for average dot size
};

const cnBoxPlotChart = block('BoxPlotChart');

const BoxPlotChart = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  let data: any = [];

  const selectedJoint = dashboardStore.selectedGesture!;

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
    }
  }, [containerRef, selectedJoint]);

  const HorizonBar = (props: RectangleProps) => {
    const { x, y, width, height } = props;

    if (x == null || y == null || width == null || height == null) {
      return null;
    }

    return <line x1={x} y1={y} x2={x + width} y2={y} stroke={'lightblue'} strokeWidth={3} />;
  };

  const DotBar = (props: RectangleProps) => {
    const { x, y, width, height } = props;

    if (x == null || y == null || width == null || height == null) {
      return null;
    }

    return (
      <line
        x1={x + width / 2}
        y1={y + height}
        x2={x + width / 2}
        y2={y}
        stroke={'lightblue'}
        strokeWidth={5}
        strokeDasharray={'5'}
      />
    );
  };

  const useBoxPlot = (boxPlots: BoxPlot[]): BoxPlotData[] => {
    const data = boxPlots.map((v) => {
      if (v == undefined) {
        dashboardStore.selectedGesture = null;
      }
      return {
        name: v?.name,
        min: v?.min,
        bottomWhisker: v?.lowerQuartile - v?.min,
        bottomBox: v?.median - v?.lowerQuartile,
        topBox: v?.upperQuartile - v?.median,
        topWhisker: v?.max - v?.upperQuartile,
        average: v?.average,
        size: 100,
      };
    });

    return data;
  };

  if (dashboardStore.selectedGestureAdvanceSetting) {
    const getGenFun = (obj: { name?: any, min?: any; max: any; q1: any; median: any; q3: any },label: string | null) => {
      if (obj == undefined) return null;
      if ('min' in obj) {
        /* if (!!obj.min == false) return; */
        return {
          name: label,
          min: obj.min,
          max: obj.max,
          lowerQuartile: obj.q1,
          median: obj.median,
          upperQuartile: obj.q3,
          average: obj.median,
        };
      }
    };
    const emptyBoxPlot ={
      min: null,
      q1:null,
      q3:null,
      median:null,
      max:null,
    };


    // eslint-disable-next-line react-hooks/rules-of-hooks
    data = useBoxPlot([
      selectedJoint?.jointData?.min != undefined ? getGenFun(selectedJoint?.jointData,'Первая дата')! : getGenFun(emptyBoxPlot,'Первая дата')!,
      selectedJoint?.jointData2?.min != undefined ? getGenFun(selectedJoint?.jointData2,'Средняя дата')! : getGenFun(emptyBoxPlot,'Первая дата')!,
      selectedJoint?.jointData3?.min != undefined ?getGenFun(selectedJoint?.jointData3,'Последняя дата')! : getGenFun(emptyBoxPlot,'Первая дата')!,
    ]);
  } 
  return (
    <>
     
        <div className={cnBoxPlotChart()} ref={containerRef}>
          <ComposedChart
            barCategoryGap={100}
            barSize={width / 15}
            width={width}
            height={height}
            data={data}
          >
            <CartesianGrid strokeDasharray="5 5" />
            <Bar stackId={'a'} dataKey={'min'} fill={'none'} />
            <Bar stackId={'a'} dataKey={'bar'} shape={<HorizonBar />} />
            <Bar stackId={'a'} dataKey={'bottomWhisker'} shape={<DotBar />} />
            <Bar stackId={'a'} dataKey={'bottomBox'} fill={'#8884d8'} />
            <Bar stackId={'a'} dataKey={'bar'} shape={<HorizonBar />} />
            <Bar stackId={'a'} dataKey={'topBox'} fill={'#8884d8'} />
            <Bar stackId={'a'} dataKey={'topWhisker'} shape={<DotBar />} />
            <Bar stackId={'a'} dataKey={'bar'} shape={<HorizonBar />} />
            <ZAxis type="number" dataKey="size" range={[0, 100]} />

            <Scatter dataKey="average" fill={'red'} stroke={'#FFF'} />
            <XAxis dataKey={'name'} />
            <YAxis label={{ value: 'Качество жеста', angle: -90, position: 'insideLeft' }} />
          </ComposedChart>
        </div>
    </>
  );
};

export default observer(BoxPlotChart);
import { DoubleSide, RawShaderMaterial, Uniform, Vector3, Vector4 } from 'three';

import { muscleFragmentShader, MuscleHighlight, muscleVertexShader } from 'shared';

interface ILightInfo {
  position: Vector3;
  color: Vector3;
  intensity: number;
}

export class MuscleMaterial extends RawShaderMaterial {
  constructor(color: Vector4, lightsInfo: ILightInfo[]) {
    super();

    this.side = DoubleSide;
    this.transparent = true;

    const positions: Vector3[] = [];
    const colors: Vector3[] = [];
    const intensions: number[] = [];

    lightsInfo.forEach((lightInfo) => {
      positions.push(lightInfo.position);
      colors.push(lightInfo.color);
      intensions.push(lightInfo.intensity);
    });

    this.uniforms = {
      color: new Uniform(color),
      shininess: new Uniform(100.0),
      needHighlight: new Uniform(MuscleHighlight.NoHighlight),
      ambientColor: { value: new Vector3(0.7, 0.7, 0.7) },
      lightPosition: { value: new Vector3(1, 1, 1) }, // Пример позиции источника света
      lightColor: { value: new Vector3(0.3, 0.3, 0.3) }, // Белый амбиентный свет
      //outlineWidth: new Uniform(0.05) // Для способа 2
    };

    this.needsUpdate = false;
    this.vertexShader = muscleVertexShader();
    this.fragmentShader = muscleFragmentShader();

    
  }

  updateHighlight(needHighlight: MuscleHighlight) {
    this.uniforms.needHighlight.value = needHighlight;
    this.needsUpdate = true;
  }
}

import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { dashboardStore, patientsStore } from 'stores';

import { PanelWrapper } from 'components';

import GestureTable from './components/GestureTable/GestureTable';
import StatsTable from './components/StatsTable/StatsTable';

import './StatisticsTable.scss';

const cnStatisticsTable = block('StatisticsTable');

interface CurrentTabProps {
  tab?: string;
}

const CurrentTab: React.FC<CurrentTabProps> = ({ tab }) => {
  if (tab) {
    if (tab === 'gesture') {
      return <GestureTable />;
    }
  }
  return <StatsTable />;
};

const StatisticsTable = (props: { tab?: string; columnsNames?: any }) => {
  
  const dynamicCheckValue = !!dashboardStore.Stats?.length;

  

  return (
    <PanelWrapper className={cnStatisticsTable()}>
      <TableContainer component={Paper} className={cnStatisticsTable('table').toString()}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{props?.columnsNames ? props?.columnsNames[0] : 'Сустав'}</TableCell>
              <TableCell align="center">
                {props?.columnsNames ? props?.columnsNames[1] : 'Длительность'}
              </TableCell>
              <TableCell
                className={
                  !dynamicCheckValue ? cnStatisticsTable('MuiTableCell-sizeSmall').toString() : ''
                }
                align="center"
              >
                {props?.columnsNames ? props?.columnsNames[2] : 'Длительность 2'}
              </TableCell>
              <TableCell
                className={
                  !dynamicCheckValue ? cnStatisticsTable('MuiTableCell-sizeSmall').toString() : ''
                }
                align="center"
              >
                {props?.columnsNames ? props?.columnsNames[3] : 'Длительность 3'}
              </TableCell>
              {props?.tab != 'gesture' && <TableCell align="center">Активность</TableCell>}
              {patientsStore.ErrNotice && <div>{patientsStore.ErrNotice}</div>}
            </TableRow>
          </TableHead>
          <CurrentTab tab={props?.tab} />
        </Table>
      </TableContainer>
    </PanelWrapper>
  );
};

export default observer(StatisticsTable);

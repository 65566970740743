import block from 'bem-cn';
import { observer } from 'mobx-react';

import './Footer.scss';

const cnFooter = block('Footer');

const Footer = () => {

  return (
    <>
     
       
        <div className={cnFooter()}>
        <span style={{fontSize: "14px"}}> Производитель Федеральное государственное бюджетное образовательное учреждение высшего образования «Сибирский государственный медицинский университет» Министерства здравоохранения Российской Федерации </span>
        </div>
      
    </>
  );
};

export default observer(Footer);

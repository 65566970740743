import {  useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { dashboardStore } from 'stores';

import { isLaptop } from '../../StatisticsComponent';
import { Lines } from '../JointLineChart/JointLineChart';

import PieChartWithNeedle from './PieChartWithNeedle/PieChartWithNeedle';

import './BoxPlotChart.scss';

const cnBoxPlotChart = block('BoxPlotChart');

const BoxPlotChart = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  let data: any = [];
  let dataBar: any[] = [];

  const selectedJoint = dashboardStore.selectedGesture!;

  const RadarArray = [];

    data = selectedJoint?.jointData;
    RadarArray.push([Lines.DATA_KEY, '#8884d8']);
    if (!!selectedJoint?.jointData2) {
      RadarArray.push([Lines.DATA_KEY3, '#6a329f'], [Lines.DATA_KEY2, '#8884d8']);
    }
 dataBar = [
  {
    "name": data[0]?.subject,
    'Средняя дата': (data[0]?.Count - data[0].CountDate3) * -1,
    'Последняя дата': (data[0]?.Count - data[0].CountDate2) * -1,
  },
  {
    "name": data[1]?.subject,
     'Средняя дата': (data[1]?.Count - data[1].CountDate3) * -1,
    'Последняя дата': (data[1]?.Count - data[1].CountDate2) * -1,
  },
  {
    "name": data[2]?.subject,
    'Средняя дата': (data[2]?.Count - data[2].CountDate3) * -1,
    'Последняя дата': (data[2]?.Count - data[2].CountDate2) * -1,
  },
];

  const [opacity, setOpacity] = useState(false);
  const [opacity2, setOpacity2] = useState(false);
  const [opacity3, setOpacity3] = useState(false);
  return (
    <>
        <div className={cnBoxPlotChart()} ref={containerRef}>
          <Grid  container justifyContent="center" spacing={2}>
          <Grid  item sm={!isLaptop ? 8 : 12} lg={5}>
            <Box className={cnBoxPlotChart('item').toString()}>
              <ResponsiveContainer width="100%">
                <RadarChart cy="60%" outerRadius={'100%'}  data={data} >
                  <PolarGrid />
                  <PolarAngleAxis orientation="inner" stroke="#ffffff" dataKey="subject"/>
                  <PolarRadiusAxis angle={30}  domain={[0, 18]} orientation='left'/>
                  <Radar
                    name={Lines.DATA_KEY}
                    dataKey="Count"
                    stroke="#FFFFFF"
                    fill="#FFFFFF"
                    onMouseEnter={() => setOpacity(true)}
                    onMouseLeave={() => setOpacity(false)}
                    fillOpacity={!opacity ? 0.2 : 1}
                  >
                    <LabelList values="Pages of my website" offset={0} position="inside" angle={0} />
                    
                  </Radar>

                  <Radar
                    name={Lines.DATA_KEY3}
                    dataKey="CountDate3"
                    stroke="#ADFF2F"
                    fill="#ADFF2F"
                    onMouseEnter={() => setOpacity3(true)}
                    onMouseLeave={() => setOpacity3(false)}
                    fillOpacity={!opacity3 ? 0.5 :  1}
                  >
                    <LabelList values="Pages of my website" offset={5} position="insideRight" angle={0} stroke="#FFFFFF"/>
                  </Radar>
                  <Radar
                    name={Lines.DATA_KEY2}
                    dataKey="CountDate2"
                    stroke="#FF69B4"
                    fill="#FF69B4"
                    onMouseEnter={() => setOpacity2(true)}
                    onMouseLeave={() => setOpacity2(false)}
                    fillOpacity={!opacity2 ? 0.6 : 1}
                  >
                    <LabelList values="Pages of my website" offset={0} position="insideTop" angle={0}/>
                  </Radar>
                  <Legend />
                </RadarChart>
              </ResponsiveContainer>
            </Box>
            </Grid>
            <Grid  item sm={!isLaptop ? 8 : 12} lg={5}>
              <Box className={cnBoxPlotChart('item').toString()}>
                  <ResponsiveContainer width="95%">
                  <BarChart
                  
                    data={dataBar}
                    margin={{
                      top: 10,
                      right: 10,
                      left: 0,
                      bottom: 10,
                    }}
                  >
                    <CartesianGrid 
                      stroke='#fff'
                      horizontal={true}
                      vertical={false}
                     strokeDasharray="3 0" />
                    <XAxis 
                        stroke='#fff' 
                        dataKey="name"
                        tickSize={10}
                        axisLine={false}
                        padding={{left: 20}}
                        tick={({ payload, x, y, dy }) => (
                          <text
                            x={x}
                            y={y + 15}
                            dy={dy}
                            textAnchor="middle"
                            fontSize={14}
                          >
                            {payload.value}
                          </text>
                        )}
                         />
                    <YAxis 
                        stroke='#fff'
                        tickSize={5}
                        
                        />
                    <Tooltip cursor={{fill: "#FFFAFA", opacity: 0.6}}/>
                    <Legend />
                    <Bar
                      dataKey="Средняя дата"
                      fill="#82ca9d"
                      activeBar={<Rectangle fill="#2A834B" />}
                    />
                    <Bar
                      dataKey="Последняя дата"
                      fill="#8884d8"
                      activeBar={<Rectangle fill="#8984D8" />}
                    />
                  </BarChart>
                  </ResponsiveContainer>
              </Box>
            </Grid>
            <Grid item sm={!isLaptop ? 8 : 12} lg={5}>
            <Box className={cnBoxPlotChart('item1').toString()}>
                <ResponsiveContainer width="100%" >
                    <PieChartWithNeedle />
                </ResponsiveContainer>
            </Box>
            </Grid>
          </Grid>
        </div>
    </>
  );
};

export default observer(BoxPlotChart);
import { makeAutoObservable } from 'mobx';
import { PatientsApiService } from 'services';

import { DataThird } from 'shared/models/dashboardModels/DataThird';

import { IGestureInfoDto, IGestureInfoDtoAdvance, IJointInfoDto } from '../shared/interfaces';
import {
  BoxPlotModel,
  DataGestureModel,
  DataGestureModel2,
  DataGestureModel3,
  DataProcessingModel,
  DataSecond,
  JointModel,
} from '../shared/models/dashboardModels';

import authStore from './authStore';

class DashboardStore {
  public dataLoader: DataProcessingModel = new DataProcessingModel();

  public dataSecondLoader: DataSecond = new DataSecond();

  public dataThirdLoader: DataThird = new DataThird();

  public GestureLoader: DataGestureModel = new DataGestureModel();

  public GestureLoader2: DataGestureModel2 = new DataGestureModel2();

  public GestureLoader3: DataGestureModel3 = new DataGestureModel3();

  public selectedJoint: JointModel | null = null;

  public selectedGesture: BoxPlotModel | null = null;

  public jointHand: 'left'| 'right' = 'left';

  public Switcher: boolean = false;

  public selectedGestureAdvanceSetting: boolean = true;

  public sizeRadarChartValue: number = 10;

  public Stats: any  = null;

  public isLoading:any = null;

  public JointData: any = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public setSelectedJoint(jointName: string, dto?: IJointInfoDto,dto2?: IJointInfoDto, dto3?: IJointInfoDto) {
    this.selectedJoint = new JointModel(jointName, dto, dto2, dto3);
  }

  public setStats(stat:number[] | null) {
    this.Stats = stat;
  }

  public setSelectedGesture(jointName: string, dto?: IGestureInfoDto[] | IGestureInfoDtoAdvance,dto2?: IGestureInfoDto[] | IGestureInfoDtoAdvance,dto3?: IGestureInfoDto[] | IGestureInfoDtoAdvance) {
   
    this.selectedGesture = new BoxPlotModel(jointName, dto, dto2, dto3);
  }

  public setJointUpdate(hand: 'left'| 'right') {
    this.jointHand = hand;
  }

  public setSizeRadarChartValue(value: number) {
    this.sizeRadarChartValue = value;
  }

  public setGestureAdvanceSetting(set: boolean) {
    this.selectedGestureAdvanceSetting = set;
  }

  public setSwitcher(switcher: boolean) {
    this.Switcher = switcher;
  }

  public async getJointData(username:string, levelname: string, joint: string){
    this.isLoading = true;

    try {
      const data = await PatientsApiService.postJoint(username,levelname,joint);
      
      this.JointData = data;
    } catch (error) {
        console.log(error)
    }finally{
      this.isLoading = false;
    }
  }

  public clearSelectedJoint() {
    this.selectedJoint = null;
    authStore.error = null;
    this.selectedGesture = null;
  }
}

export default new DashboardStore();

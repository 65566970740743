import { useEffect } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { dashboardStore, patientsStore, sceneStore } from 'stores';

import { ERROR_COLOR, SUCCESS_COLOR, WARNING_COLOR } from 'shared/constants';

import './StatsTable.scss';

const GRADIENT_STEPS = 20;

const getPercentageString = (ooc: number) => {
  return ooc + '%';
};

const getGradientSteps = (ooc: number): null[] => {
  const stepsCount = Math.ceil((ooc * GRADIENT_STEPS) / 100);

  return new Array(stepsCount).fill(null);
};

const getGradientColor = (currentStep: number) => {
  if (currentStep < GRADIENT_STEPS * 0.4) {
    return ERROR_COLOR;
  } else if (currentStep < GRADIENT_STEPS * 0.7) {
    return WARNING_COLOR;
  } else {
    return SUCCESS_COLOR;
  }
};

const cnStatsTable = block('StatsTable');

const StatsTable = () => {

  useEffect(() => {
    if (dashboardStore.selectedJoint?.name) {
      if(dashboardStore.jointHand === "left"){
        dashboardStore.setSelectedJoint( dashboardStore.JointData.first_statLeft.column, dashboardStore.JointData.first_statLeft, dashboardStore.JointData.midle_statLeft, dashboardStore.JointData.last_statLeft);
      }else{
        dashboardStore.setSelectedJoint( dashboardStore.JointData.first_statLeft.column, dashboardStore.JointData.first_statRight, dashboardStore.JointData.midle_statRight, dashboardStore.JointData.last_statRight);
      }
    }
  }, [dashboardStore.jointHand]);
  return (
    <TableBody>
      {patientsStore.statResult?.[`results_${dashboardStore.jointHand}`]?.map(
        (column: any, index: number) => {
          const valueColumn = column.ooc_values;
          const valueColumn2 =
            patientsStore.statResult[`results_${dashboardStore.jointHand}_1`][index].ooc_values;
          const valueColumn3 =
            patientsStore.statResult[`results_${dashboardStore.jointHand}_2`][index].ooc_values;

          function sumJointValues(...numbers: any[]) {
            if (numbers.length === 0) return 0; // Если нет чисел, возвращаем 0

            const sum = numbers.reduce((acc, num) => acc + num, 0); // Суммируем все числа
            const average = sum / numbers.length; // Вычисляем среднее арифметическое

            return average;
          }

          const gradientSteps = getGradientSteps(
            valueColumn2 && valueColumn3
              ? sumJointValues(valueColumn, valueColumn2, valueColumn3)
              : valueColumn,
          );


          const selectRow = async () => {
            await dashboardStore.getJointData(sceneStore.user, patientsStore.CurrentLevel, column.column);

            if(dashboardStore.jointHand === "left"){
              dashboardStore.setSelectedJoint(column.column, dashboardStore.JointData?.first_statLeft, dashboardStore.JointData?.midle_statLeft, dashboardStore.JointData?.last_statLeft);
            }else{
              dashboardStore.setSelectedJoint(column.column, dashboardStore.JointData?.first_statRight, dashboardStore.JointData?.midle_statRight, dashboardStore.JointData?.last_statRight);
            }
            
          };

          return (
            <TableRow
              key={valueColumn + index}
              onClick={selectRow}
              className={cnStatsTable('row', {
                selected: dashboardStore.selectedJoint?.name === column.column,
              }).toString()}
            >
              <TableCell>{column.column}</TableCell>
              <TableCell align="center">{getPercentageString(valueColumn)}</TableCell>
              {<TableCell align="center">{getPercentageString(valueColumn2)}</TableCell>}
              {<TableCell align="center">{getPercentageString(valueColumn3)}</TableCell>}
              <TableCell align="center">
                <div
                  className={cnStatsTable('gradient')}
                  style={{ width: `${GRADIENT_STEPS * 6}px` }}
                >
                  {gradientSteps.map((_, index) => {
                    return (
                      <div
                        key={index++}
                        className={cnStatsTable('gradientStep')}
                        style={{ backgroundColor: getGradientColor(index + 1) }}
                      ></div>
                    );
                  })}
                </div>
              </TableCell>
            </TableRow>
          );
        },
      )}
    </TableBody>
  );
};

export default observer(StatsTable);

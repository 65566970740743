import React from 'react';

interface ITooltip {
  name: string,
  position: { x: number, y: number },
}

const Tooltip = React.memo(({ name, position }: ITooltip) => {
  if (!name) return null;

  return (
    <div
      style={{
        position: 'absolute',
        left: position.x + 10,
        top: position.y + 10,
        backgroundColor: 'white',
        color: 'rgb(66, 65, 65)',
        padding: '10px 15px',
        borderRadius: '15px',
        border: '1px solid gray',
        fontSize: '18px',
        pointerEvents: 'none',
      }}
    >
      {name}
    </div>
  );
});

export default Tooltip;
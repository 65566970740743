import CloseIcon from '@mui/icons-material/Close';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { sceneStore } from 'stores';

import HandModalItem from './HandModalItem';

import './HandModal.scss';

export interface Hands {
  first_PowerLeft: any;
  first_PowerRight: any;
  last_PowerLeft: any;
  last_PowerRight: any;
  midle_PowerLeft: any;
  midle_PowerRight: any;
}
const cnHandModal = block('HandModal');

const HandModal = ({ classname, handOrientation }: any) => {
  return (
    <div className={`${classname}` + ' ' + cnHandModal()}>
      <div className="closeBtn">
        <span className="hand">Рука: {handOrientation === 'right' ? 'Левая' : 'Правая'}</span>
        <CloseIcon className="closeBtnText" onClick={() => (sceneStore.modalInfo = null)} />
      </div>
      <span className="line"></span>
      <div className="titleModal">
        {sceneStore.modalInfo.type.type}:
        <div className="titleModal-column">
          <span>
            {' '}
            {handOrientation === 'right'
              ? sceneStore.modalInfo.left.name
              : sceneStore.modalInfo.right.name}
          </span>
        </div>
      </div>
      <div className="paramsWrap">
        {sceneStore.modalInfo.left.value && (
          <HandModalItem
            title={`Энергитические затраты:`}
            value={`${
              handOrientation === 'right'
                ? sceneStore.modalInfo.left.value
                : sceneStore.modalInfo.right.value
            }Дж`}
          />
        )}
        {sceneStore.modalInfo.left.strength && (
          <HandModalItem
            title={`Максимальная сила:`}
            value={`${
              handOrientation === 'right'
                ? Math.round(sceneStore.modalInfo.left.strength)
                : Math.round(sceneStore.modalInfo.right.strength)
            }Н/с`}
          />
        )}
        {sceneStore.modalInfo.left.power && (
          <HandModalItem
            title={`Мощность:`}
            value={`${
              handOrientation === 'right'
                ? Math.round(sceneStore.modalInfo.left.power)
                : Math.round(sceneStore.modalInfo.right.power)
            }Вт`}
          />
        )}

        {sceneStore.modalInfo?.left?.angles?.length > 2 ? (
          <>
            <HandModalItem
              title={`Максимальное отведение:`}
              value={`${
                handOrientation === 'right'
                  ? sceneStore.modalInfo.left?.angles[0]
                  : sceneStore.modalInfo.right?.angles[0]
              }°`}
            />
            <HandModalItem
              title={`Минимальное отведение:`}
              value={`${
                handOrientation === 'right'
                  ? sceneStore.modalInfo.left.angles[1]
                  : sceneStore.modalInfo.right.angles[1]
              }°`}
            />
            <HandModalItem
              title={`Максимальное сгибание:`}
              value={`${
                handOrientation === 'right'
                  ? sceneStore.modalInfo.left.angles[2]
                  : sceneStore.modalInfo.right.angles[2]
              }°`}
            />
            <HandModalItem
              title={`Минимальное сгибание:`}
              value={`${
                handOrientation === 'right'
                  ? sceneStore.modalInfo.left.angles[3]
                  : sceneStore.modalInfo.right.angles[3]
              }°`}
            />
          </>
        ) : (
         sceneStore.modalInfo?.left?.angles && <>
            <HandModalItem
              title={`Максимальное сгибание:`}
              value={`${
                handOrientation === 'right'
                  ? sceneStore.modalInfo.left?.angles[0]
                  : sceneStore.modalInfo.right?.angles[0]
              }°`}
            />
            <HandModalItem
              title={`Минимальное сгибание:`}
              value={`${
                handOrientation === 'right'
                  ? sceneStore.modalInfo.left.angles[1]
                  : sceneStore.modalInfo.right.angles[1]
              }°`}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default observer(HandModal);

import { makeAutoObservable, runInAction } from 'mobx';

import { PatientsApiService } from '../services';
import { IPatientDates, IPatientGetAttempt, IPatientGetDto } from '../shared/interfaces';

import dashboardStore from './dashboardStore';
import sceneStore from './sceneStore';

class PatientsStore {
  public isLoading = false;

  public searchValue = '';

  public patients: IPatientGetDto[] = [];

  public patientsOnly: IPatientGetDto[] = [];

  public doctors: any[] = [];

  public userDataGesture: any = {};

  public client: IPatientGetDto | null = null;

  public patient: IPatientGetDto | null = null;

  public patientData: IPatientDates[] | null = [];

  public patientDynamic: any[] | null = [];
  public dynamic: boolean = false;

  public patientDataAttempt: IPatientGetAttempt[] = [];

  public patientDateCSV: any = [];

  public statResult: any = [];

  public selectedPatientId: number | null = null;

  public selectedPatientDate: string | null = null;

  public selectedPatientAttempt: number | null = null;

  public ErrNotice: any = null;

  public SelectedPatientName: any = null;

  public CurrentLevel: string = "";

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public get filteredPatients() {
    return this.patients?.filter((patient) =>
      patient.username.toLowerCase().includes(this.searchValue.toLowerCase()),
    );
  }

  public setSearchValue(searchValue: string) {
    this.searchValue = searchValue;
  }

  public async selectPatient(selectedPatientId: number) {
    this.selectedPatientId = selectedPatientId;
    this.isLoading = true;

    try {
      const patientDataApi = await PatientsApiService.postPatientInfo(selectedPatientId);
      const patientInfo = await PatientsApiService.postPatientProfile(selectedPatientId);

      runInAction(() => {
        this.patient =patientInfo;
        this.SelectedPatientName = patientInfo.user.username;
        this.patientData = patientDataApi;
        this.selectedPatientDate = null;
        this.patientDataAttempt = [];
        this.selectedPatientAttempt = null;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async selectPatientDate(selectedPatientDate: string) {
    this.selectedPatientDate = selectedPatientDate;
    this.isLoading = true;

    try {
      const patientDataAttempt = await PatientsApiService.postPatientAttemptInfo(
        this.selectedPatientId!,
        selectedPatientDate,
      );
      runInAction(() => {
        this.patientDataAttempt = patientDataAttempt;
        this.selectedPatientAttempt = null;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async selectPatientAttempt(selectPatientAttempt: number) {
    this.selectedPatientAttempt = selectPatientAttempt;
  }

  public async selectPatientDynamicCSV(level: string) {
    dashboardStore.clearSelectedJoint();
    this.isLoading = true;

    try {
      this.CurrentLevel = level
      this.getPacientProfile(this.selectedPatientId!)
      const patientDateCSV = await PatientsApiService.postPatientDynamic(
        this.selectedPatientId!,
        level,
      ); //? this.selectedPatientId : 68
      sceneStore.setUser(this.patient?.user.username);
      const data = await PatientsApiService.postUserDataGesture(this.patient?.user.username,  level); //this.client!.user.username,
      const statResult = await PatientsApiService.postStatDynamic(this.patient?.user.id!,  level); //this.client!.user.username,
       
      runInAction(() => {
        this.statResult =statResult;
        this.patientDateCSV = patientDateCSV;
        this.dynamic = true;
        this.userDataGesture = data;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async selectPatientDateCSV(level: string) {
    this.isLoading = true;
    dashboardStore.clearSelectedJoint();
    try {
      if(this.selectedPatientId){
        this.getPacientProfile(this.selectedPatientId)
      const patientDateCSV = await PatientsApiService.postPatientDateCSV(
        this.selectedPatientAttempt || 109,
        level,
      );
      this.patientDateCSV = patientDateCSV;
      }
      

      const data = await PatientsApiService.postUserDataGesture(this.patient!.username || 'Firsova', level);
      runInAction(() => {
        sceneStore.setUser("Firsova");
        
        this.dynamic = false;
        this.userDataGesture = data;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async getPatients() {
    this.isLoading = true;
    try {
      this.patientDateCSV = [];
      dashboardStore.dataLoader.openJsonFile();
      const data = await PatientsApiService.getPatients();

      runInAction(() => {
        this.patients = data;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async getPatientsOnly() {
    this.isLoading = true;
    try {
      const data = await PatientsApiService.getPatientsOnly();

      runInAction(() => {
        this.patientsOnly = data;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async getDoctors() {
    this.isLoading = true;
    try {
      const data = await PatientsApiService.getDoctors();

      runInAction(() => {
        this.doctors = data;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async getProfile() {
    this.isLoading = true;
    try {
      const client = await PatientsApiService.getProfile();
      runInAction(() => {
        this.client = client;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async getPacientProfile(id: number) {
    this.isLoading = true;
    try {
      const patient = await PatientsApiService.postPatientProfile(id);
      runInAction(() => {
        this.patient = patient;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  public deInit() {
    this.patients = [];
    this.searchValue = '';
    //this.selectedPatientId = null;
    this.patientDataAttempt = [];
    this.patientData = null;
    this.selectedPatientAttempt = null;
  }
}

export default new PatientsStore();

import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import { Vector2 } from 'three';

import { patientsStore, sceneStore } from 'stores';

import { Loader } from 'components';
import Modal from 'components/CommonComponents/Modal';
import { RouterPathEnum } from 'shared';

import { ReactComponent as Arrow } from '../../Icons/arrow.svg';
import SceneApiService from '../../services/api/SceneApiService';

import Dashboard from './components/Dashboard/Dashboard';
import HandModal from './components/HandModal';
import HelpTooltip from './components/HelpTooltip/HelpTooltip';
import Tooltip from './components/Tooltip/Tooltip';

import './ScenePage2.scss';

export interface Hands {
  first_PowerLeft: any;
  first_PowerRight: any;
  last_PowerLeft: any;
  last_PowerRight: any;
  midle_PowerLeft: any;
  midle_PowerRight: any;
}
const cnScenePage = block('ScenePage2');

const ScenePage2 = () => {
  const sceneWrapperRef = useRef<HTMLDivElement>(null);
  const user = patientsStore.SelectedPatientName;
  const [currentLevel, setCurrentLevel] = useState(sceneStore.currentLvl);
  const [currentData, setCurrentData] = useState('first');
  const [openGestureModal, setOpenGestureModal] = useState(false);
  const [stopChecking, setStopChecking] = useState(false);
  const [showBtns, setShowBtns] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Используем useState
  const steps = [1, 2, 3, 4, 5];
  const navigate = useNavigate();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [error, setError] = useState(false);
  //positionRef

  useEffect(() => {
    if (!user) {
      navigate('/'); // Редиректим на главную страницу, если пользователя нет
    }
  }, []);

  useEffect(() => {
    setCurrentLevel(sceneStore.currentLvl);
  }, [sceneStore.currentLvl]);

  const mouseDownEvent = () => {
    setStopChecking(true);
  };

  const mouseUpEvent = () => {
    setStopChecking(false);
  };

  const checkIntersection = (e: PointerEvent) => {
    if (stopChecking) return;
    if (!sceneWrapperRef.current || !e?.isPrimary) return;

    const x = (e.offsetX / sceneWrapperRef.current.offsetWidth) * 2 - 1;
    const y = -(e.offsetY / sceneWrapperRef.current.offsetHeight) * 2 + 1;

    // Обновляем позицию только если она изменилась
    if (position.x !== e.offsetX || position.y !== e.offsetY) {
      setPosition({ x: e.offsetX, y: e.offsetY });
    }

    sceneStore.checkIntersection(new Vector2(x, y));
  }; // Дебаунс на 100 мс

  useEffect(() => {
    if (sceneWrapperRef.current) {
      sceneWrapperRef.current.addEventListener('pointermove', checkIntersection);
      sceneWrapperRef.current.addEventListener('mousedown', mouseDownEvent);
      sceneWrapperRef.current.addEventListener('mouseup', mouseUpEvent);
    }

    return () => {
      if (sceneWrapperRef.current) {
        sceneWrapperRef.current.removeEventListener('pointermove', checkIntersection);
        sceneWrapperRef.current.removeEventListener('mousedown', mouseDownEvent);
        sceneWrapperRef.current.removeEventListener('mouseup', mouseUpEvent);
      }
    };
  }, [sceneWrapperRef.current, stopChecking]);

  const fetchData = useCallback(
    async (dataKey: string) => {
      sceneStore.clearHandsModel();
      if (sceneStore.effectComposer.passes.length == 0) {
        sceneStore.initScene(sceneWrapperRef.current!);
      }

      try {
        sceneStore.isLoading = true;
        const data: any = await SceneApiService.postTask(user, `level${currentLevel}`);
        const leftKey = `${dataKey}_PowerLeft`;
        const rightKey = `${dataKey}_PowerRight`;
        const JointsleftKey = `${dataKey}_JointLeft`;
        const JointsrightKey = `${dataKey}_JointRight`;
        const hands = { ...data[leftKey], ...data[rightKey] };
        const joints = { ...data[JointsleftKey], ...data[JointsrightKey] };
        sceneStore.setGradientMuscle(hands);
        sceneStore.setJointsData(joints);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
        setError(true);
        sceneStore.isLoading = false;
      } finally {
        sceneStore.isLoading = false;
      }

      return () => {
        // Функция очистки для deInit при размонтировании компонента
        sceneStore.fullDeInit();
      };
    },
    [user, currentLevel],
  );

  useEffect(() => {
    if (sceneStore.joints) {
      sceneStore.initModel();
    }
  }, [sceneStore.joints]);

  useEffect(() => {
    if (sceneWrapperRef.current) {
      sceneStore.setMuscleClear();
      fetchData(currentData);
    }
  }, [fetchData, currentData]);

  useEffect(() => {
    const onResize = () => {
      if (sceneWrapperRef.current) {
        sceneStore.updateSceneSizes(
          sceneWrapperRef.current.clientWidth,
          sceneWrapperRef.current.clientHeight,
        );
      }
    };

    if (sceneWrapperRef.current) {
      sceneStore.initScene(sceneWrapperRef.current);
    }

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const cleanupScene = () => {
    sceneStore.deInit();
    sceneStore.handModel?.clear();
    sceneStore.modalInfo = null;
  };

  useEffect(() => {
    return cleanupScene;
  }, []);

  useEffect(() => {
    if (currentData) {
      fetchData(currentData);
    }
  }, [currentData, fetchData]);

  const changeCameraPosition = (direction: string) => {
    sceneStore.setCameraPosition(direction);
  };

  const openGesutreModal = (stateGesture: boolean) => {
    setOpenGestureModal(stateGesture);
  };

  const canShowBtns = (e: any) => {
    if (e.target.checked && sceneStore.showButtons) {
      setShowBtns(true);
    } else {
      setShowBtns(false);
    }
  };

  const getDashboardPage = () => {
    patientsStore.selectPatientDynamicCSV('level' + currentLevel);
    navigate(RouterPathEnum.Dashboard);
  };

  const getGestureModal = () => {
    patientsStore.patientDateCSV = [];
    //if (patientsStore.patientDateCSV.length >= 0) {
    patientsStore.selectPatientDynamicCSV('level' + currentLevel);
    //}
    openGesutreModal(!openGestureModal);
  };

  useEffect(() => {
    if (!localStorage.getItem('firstTime') === true) {
      setShowHelp(true);
      localStorage.setItem('firstTime', 'true');
    }
  }, []);

  return (
    <div className={`scene-pageWrapper `}>
      <Dashboard
        showHelp={showHelp}
        currentStep={currentStep}
        user={user}
        currentData={currentData}
        currentLevel={currentLevel}
        setCurrentLevel={setCurrentLevel}
        setCurrentData={setCurrentData}
      />
      {showHelp && (
        <HelpTooltip
          setShowHelp={setShowHelp}
          steps={steps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}

      <Modal
        className={`popUpGesturesBtn ${showHelp && currentStep != 2 ? 'showBlur' : ''}`}
        title={'Жесты'}
        anchor={'right'}
        type={'Gestures'}
        icon={<DoubleArrowIcon />}
        event={() => getGestureModal()}
        
      />

      <div
        onClick={() => getDashboardPage()}
        className={`popUpGesturesBtn-link ${showHelp && currentStep != 2 ? 'showBlur' : ''}`}
      >
        <DoubleArrowIcon /> <span>Больше информации и статистики</span>
      </div>
      <div
        className={`${cnScenePage()} ${showHelp && (currentStep === 1 || currentStep === 2) ? 'showBlur' : ''} `}
        ref={sceneWrapperRef}
      >
        <div className={`settings ${showHelp && currentStep != 3 ? 'showBlur' : ''}`}>
          <div className="checkboxWrap" onClick={(e) => canShowBtns(e)}>
            Показать кнопки:
            <input type="checkbox" checked={showBtns} onChange={(e) => canShowBtns(e)} />
          </div>
          <div
            className="checkboxWrap"
            onClick={() => sceneStore.setAutoRotate(!sceneStore.autoRotate)}
          >
            Автовращение
            <input
              type="checkbox"
              checked={sceneStore.autoRotate}
              onChange={(e) => sceneStore.setAutoRotate(e.target.checked)}
            />
          </div>
          <div className="checkboxWrap">
            <button
              className="tooltips"
              onClick={() => {
                setShowHelp(true);
                setCurrentStep(1);
              }}
            >
              Вопсроизвести подсказки
            </button>
          </div>
          <div className="btnPatient">
            <Modal title={'Выбрать пациента'} />
          </div>
        </div>
        {!!sceneStore.modalInfo && (
          <>
            <HandModal classname="ModalLeftHand" handOrientation={'right'} />
            <HandModal classname="ModalRightHand" handOrientation={'left'} />
          </>
        )}
        {error && (
          <div className="error">
            Произошла ошибка обработки данных, обратитесь позже или выберете другого пациента
          </div>
        )}
        {sceneStore.isLoading && <Loader visible={sceneStore.isLoading} />}
        {showBtns && sceneStore.showButtons && (
          <>
            <div className="TestBtn top" onClick={() => changeCameraPosition('top')}>
              <span>Вращать вверх</span>
              <Arrow className="logo1" />
            </div>
            <div className="TestBtn right" onClick={() => changeCameraPosition('right')}>
              <span>Вращать вправо</span>
              <Arrow className="logo1" />
            </div>
            <div className="TestBtn left" onClick={() => changeCameraPosition('left')}>
              <span>Вращать влево</span>
              <Arrow className="logo1" />
            </div>
            <div className="TestBtn bot" onClick={() => changeCameraPosition('bottom')}>
              <span>Вращать вниз</span>
              <Arrow className="logo1" />
            </div>
          </>
        )}
        {sceneStore.hoverInfo?.left?.name && (
          <Tooltip name={sceneStore.hoverInfo?.left?.name} position={position} />
        )}
      </div>
    </div>
  );
};

export default observer(ScenePage2);

/* eslint-disable prefer-const */
import { makeAutoObservable } from 'mobx';
import {
  /*  AmbientLight, */
  Box3,
  Color,
  Intersection,
  Mesh,
  MeshPhysicalMaterial,
  Object3D,
  Object3DEventMap,
  PointLight,
  Vector3,
  Vector4,
} from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { sceneStore } from 'stores';

import { HandModelMeshNames, MuscleHighlight } from 'shared/enums';
import { ISceneStore } from 'shared/interfaces';
import { MuscleMaterial } from 'shared/materials';
import { ColorUtils } from 'shared/utils';

import modelPath from '../../../assets/models/scene.glb';

export class HandProcessingModel {
  private readonly sceneStore: ISceneStore | null = null;

  private modelStructures: Record<string, Mesh> = {};

  public meshNames: any[] = [];

  constructor(sceneStore: ISceneStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.sceneStore = sceneStore;
  }

  public init(lights: PointLight[]) {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();

    dracoLoader.setDecoderPath('/js/libs/draco-new/');
    loader.setDRACOLoader(dracoLoader);

    loader.load(modelPath, (gltf) => {
      const modelData: any = gltf.scene;
      this.sceneStore!.scene.remove(modelData);
      this.sceneStore!.scene.add(modelData);
      const bb = new Box3();
      if (this.sceneStore?.scene.children) {
        removeDuplicateGroups(this.sceneStore.scene.children);
      }

      function removeDuplicateGroups(arr: Object3D<Object3DEventMap>[] | any[]) {
        // Создаем массив для хранения индексов элементов с type = 'Group'
        const groupIndices = [];

        // Проходим по массиву и находим индексы элементов с type = 'Group'
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].type === 'Group') {
            groupIndices.push(i);
          }
        }

        // Удаляем все элементы с type = 'Group', кроме последнего
        for (let i = groupIndices.length - 2; i >= 0; i--) {
          const indexToRemove = groupIndices[i];
          arr.splice(indexToRemove, 1);

          // Корректируем индексы после удаления элемента
          for (let j = i; j < groupIndices.length - 1; j++) {
            groupIndices[j] = groupIndices[j] - 1;
          }
        }

        return arr;
      }

      const lightsInfo = lights.map((light) => ({
        position: light.position.clone(),
        color: new Vector3(light.color.r, light.color.g, light.color.b),
        intensity: light.intensity,
      }));
      const accamulateResults: any = [];
      for (const child of modelData.children) {
        const collectIsMeshObjects = (obj: any): any[] => {
          let result: any = [];
          // Проверяем, есть ли свойство isMesh у текущего объекта
          if (obj.isMesh) {
            result.push(obj);
          }

          // Если есть свойство children и это массив, проходимся по каждому элементу
          if (Array.isArray(obj.children)) {
            for (let child of obj.children) {
              // Рекурсивно собираем объекты с isMesh из дочерних объектов
              result = result.concat(collectIsMeshObjects(child));
            }
          }

          return result;
        };

        for (const mesh of collectIsMeshObjects(child)) {
          bb.expandByObject(mesh);
          const currentMesh = mesh as Mesh;
          accamulateResults.push(mesh);
          if (currentMesh.name === HandModelMeshNames.Bones) {
            currentMesh.material = new MeshPhysicalMaterial({
              color: new Color(0.89, 0.85, 0.79),
              reflectivity: 0.5,
              roughness: 0.4,
            });

            continue;
          }

          if (mesh.name === sceneStore.muscle) {
            currentMesh.material = new MuscleMaterial(new Vector4(0, 0, 139, 1.0), lightsInfo);
            this.sceneStore!.outlinePass!.selectedObjects = [currentMesh];
          } else {
            const regexp = /[а-яё]/i;

            if (regexp.test(mesh.name)) {
              this.meshNames.push(mesh.name);

              const colorMesh = ColorUtils.generateGradient(sceneStore.gradientMuscle[mesh.name]); //добавить [0] элемент для расскраски мышц.

              currentMesh.material = new MuscleMaterial(
                new Vector4(colorMesh[0], colorMesh[1], colorMesh[2], 1.0),
                lightsInfo,
              );

              function processString(str: any) {
                const contains001 = str.includes('001');
                const contains2 = str.includes('_2');
                let rightHand = '';

                const originalString = str;
                const stringWithout001 = contains001 ? str.replace('001', '') : str;

                if (contains001) {
                  if (contains2) {
                    rightHand = str.replace(/001_1/, '001_2');
                  } else {
                    rightHand = str;
                  }
                } else {
                  if (contains2) {
                    rightHand = str.replace(/_(\d+)/, '001_2');
                  } else {
                    rightHand = str.replace(/_(\d+)/, '001_1');
                  }
                }

                return {
                  original: originalString,
                  left: stringWithout001,
                  right: rightHand,
                };
              }

              currentMesh.userData.left = {
                name: processString(mesh.name).left,
                value: sceneStore.gradientMuscle[processString(mesh.name).left],
                strength: sceneStore.strengh[processString(mesh.name).left],
                power: sceneStore.power[processString(mesh.name).left],
              };
              currentMesh.userData.right = {
                name: processString(mesh.name).right,
                value: sceneStore.gradientMuscle[processString(mesh.name).right],
                strength: sceneStore.strengh[processString(mesh.name).right],
                power: sceneStore.power[processString(mesh.name).right],
              };

              currentMesh.userData.type = {
                type: 'Мышца',
              };

              if (mesh.name.startsWith('с_')) {
                currentMesh.userData.type = {
                  type: 'Сустав',
                };

                currentMesh.userData.left = {
                  name: processString(mesh.name).left,
                  angles: sceneStore.joints[processString(mesh.name).left],
                };

                currentMesh.userData.right = {
                  name: processString(mesh.name).right,
                  angles: sceneStore.joints[processString(mesh.name).right],
                };

                currentMesh.material = new MuscleMaterial(
                  new Vector4(0.65, 0.65, 0.65, 1.0),
                  lightsInfo,
                );
              }

              if (mesh.name.includes('_2')) {
                currentMesh.userData.type = {
                  type: 'Сухожилие',
                };

                currentMesh.material = new MuscleMaterial(
                  new Vector4(0.502, 0.502, 0.502, 1.0),
                  lightsInfo,
                );
              }
            } else {
              currentMesh.material = new MuscleMaterial(
                new Vector4(0.961, 0.871, 0.702, 1.0),
                lightsInfo,
              );
            }
          }
          this.modelStructures[currentMesh.name] = currentMesh;

          //test соответсвие мешей модели и данных с бэка для неё

          //   class ArrayComparer {
          //     private array1: string[];
          //     private array2: string[];

          //     constructor(array1: string[], array2: string[]) {
          //         this.array1 = array1;
          //         this.array2 = array2;
          //     }

          //     public findMissingElements(): { missingInArray2: string[]; missingInArray1: string[] } {
          //         const setB = new Set(this.array2);

          //         // Элементы из array1, которые отсутствуют в array2
          //         const missingInArray2 = this.array1.filter(element => !setB.has(element));

          //         const setA = new Set(this.array1);
          //         // Элементы из array2, которые отсутствуют в array1
          //         const missingInArray1 = this.array2.filter(element => !setA.has(element));

          //         return {
          //             missingInArray2,
          //             missingInArray1
          //         };
          //     }
          // }
          // const keys =Object.keys(sceneStore.joints);
          // // Пример использования
          // const arrayA: string[] = this.meshNames;
          // const arrayB: string[] = keys;

          // const comparer = new ArrayComparer(arrayA, arrayB);
          // const result = comparer.findMissingElements();
          // console.log(arrayA,"1232131")
          // console.log("Элементы из Model, которые отсутствуют в Даныые с бэка:", result.missingInArray2); // [1, 2, 3]
          // console.log("Элементы из Даныые с бэка, которые отсутствуют в Model:", result.missingInArray1, arrayA); // [6, 7, 8]
        }
      }

      this.sceneStore!.zoomTo(bb);
    });
  }

  public clear() {
    const scene = this.sceneStore?.scene;
    function collectAndDisposeMeshes(object: Object3D | undefined): Mesh[] {
      const meshes: Mesh[] = [];

      function recursiveSearch(obj: Object3D) {
        obj.children.forEach((child) => {
          if (child instanceof Mesh) {
            meshes.push(child);
          } else if (child.children.length > 0) {
            recursiveSearch(child);
          }
        });
      }

      if (object) {
        recursiveSearch(object);
      }

      meshes.forEach((mesh: any) => {
        const parent = mesh.parent;
        if (parent) {
          parent.remove(mesh);
          mesh.geometry?.dispose();
          mesh.material?.map?.dispose();
          mesh.material?.dispose();
        }
      });

      return meshes;
    }

    collectAndDisposeMeshes(scene);
    const objects = this.sceneStore?.outlinePass;
    objects?.dispose();
    let renderer = this.sceneStore?.renderer; // Убедитесь, что renderer существует
    renderer?.dispose();
  }

  public clearPrevSelectedObjectHighlight() {
    if (this.sceneStore!.outlinePass && this.sceneStore!.outlinePass.selectedObjects.length) {
      const objects = this.sceneStore!.outlinePass.selectedObjects;
      objects.map((object) => {
        const material = (object as Mesh).material as MuscleMaterial;

        if (object.name !== HandModelMeshNames.Skeleton)
          material.updateHighlight(MuscleHighlight.NoHighlight);
      });
    }
  }

  handleClick(intersects: Intersection[]) {
    this.clearSelection();
    if (intersects.length === 0) return;
    intersects.sort((a, b) => a.distance - b.distance);
    const closestIntersect = intersects[0]; // Берем ближайший объект
    const selectedObject = closestIntersect.object as Mesh;
    const regexp = /[а-яё]/i;

    if (regexp.test(selectedObject.name)) {
      this.selectObject(selectedObject);
    } else {
      this.clearSelection();
    }
  }

  selectObject(selectedObject: Mesh) {
    this.clearSelection();
    sceneStore.modalInfo = selectedObject.userData;
    this.sceneStore!.outlinePass!.selectedObjects = [
      this.modelStructures[selectedObject.userData.left.name],
      this.modelStructures[selectedObject.userData.right.name],
    ];
  }

  hoverObject(selectedObject: Mesh) {
    this.clearSelection();
    sceneStore.hoverInfo = selectedObject.userData;
    this.sceneStore!.outlinePass!.selectedObjects = [
      this.modelStructures[selectedObject.userData.left.name],
      this.modelStructures[selectedObject.userData.right.name],
    ];
  }

  clearSelection() {
    this.clearPrevSelectedObjectHighlight();
    sceneStore.hoverInfo = null;
    this.sceneStore!.outlinePass!.selectedObjects = [];
    sceneStore.modalInfo = null; // Очищаем modalInfo
  }

  updateModelByIntersections(intersects: Intersection[]) {
    if (!this.sceneStore!.outlinePass) return;
    sceneStore.hoverInfo = null;
    const regexp = /[а-яё]/i;
    if (!sceneStore.modalInfo) {
      this.clearPrevSelectedObjectHighlight(); // Перемещено сюда для очистки перед каждой обработкой
      intersects.sort((a, b) => a.distance - b.distance);

      // Only process the closest intersection
      const closestIntersect = intersects[0];
      const selectedObjects: Mesh[] = [];

      if (closestIntersect?.object) {
        const selectedObject = closestIntersect?.object as Mesh;
        const material = selectedObject.material as MuscleMaterial;
        if (regexp.test(selectedObject.name)) {
          this.hoverObject(selectedObject);

          material.updateHighlight(MuscleHighlight.HasHighlight);
          selectedObjects.push(selectedObject);
        } else {
          this.clearPrevSelectedObjectHighlight();

          this.sceneStore!.outlinePass.selectedObjects = [];
        }

        this.sceneStore!.outlinePass.selectedObjects = selectedObjects;
      }
    }
  }
}

import { useNavigate } from 'react-router-dom';
import { List, ListItemButton, ListItemText } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { sceneStore } from 'stores';
import patientsStore from 'stores/patientsStore';

import { Loader } from 'components';
import { RouterPathEnum } from 'shared';

import './Levels.scss';

const cnLevels = block('Levels');

interface LevelsProps {
  dynamic: boolean;
}

const Levels = ({dynamic}:LevelsProps) => {
  const Levels = [1, 2, 3, 4, 5, 6];
  const patientSelected = patientsStore.selectedPatientId == null;
  const patientDateSelected = patientsStore.selectedPatientDate == null;

  const navigate = useNavigate();

  const onClick = (level: number) => {

    //dynamic ? patientsStore.selectPatientDynamicCSV('level' + level) : patientsStore.selectPatientDateCSV('level' + level);
    
   sceneStore.setUser(patientsStore.patient?.user.username);
   sceneStore.currentLvl = level;
    navigate(RouterPathEnum.Scene);
  };

  return (
    <>
      { (dynamic ? !patientSelected : !patientDateSelected) && (
        patientsStore.isLoading ? <Loader />:
        <div className={cnLevels('wrap').toString()}>
          Уровни:
          <List className={cnLevels('nav').toString()}>
            {Levels.map((level: number) => {
              return (
                <ListItemButton
                  key={level}
                  className={cnLevels('lvls').toString()}
                  onClick={() => onClick(level)}
                >
                  <ListItemText primary={level} />
                </ListItemButton>
              );
            })}
          </List>
        </div>
      )}
    </>
  );
};

export default observer(Levels);
